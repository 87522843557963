/**
 * don't copy this implementation
 * we use a single source of state without provider
 * to make this context "global"
 */

import hash from "@emotion/hash"
import { createContext, useContext } from "react"

export const ctx__ONLY_FOR_HEADER__ = {}
const Context = createContext(ctx__ONLY_FOR_HEADER__)
export const Provider = ({ children }) => children

export function useHeader() {
  return useContext(Context)
}

export const cssVarExpansion = `--${hash("header:expansion")}`
export const cssVarFocusIn = `--${hash("header:focus:in")}`
export const cssVarHeight = `--${hash("header:height")}`
export const cssVarTransitionDuration = `--${hash("header:transition:duration")}`
export const cssVarTransitionDelay = `--${hash("header:transition:delay")}`

export const cssVarFreeze = `--${hash("header:freeze")}`
export const cssVarHideSubNav = `--${hash("header:subNav:hidden")}`
