import { useLayoutEffect, useState, useRef } from "react"
import { useConsole } from "contexts/Console"
import { AnimatePresence } from "framer-motion"

import { useNavigation } from "contexts/Navigation"
import { useLocale } from "contexts/Locale"
import { useHeader } from "contexts/Header"
import { useDictionary } from "contexts/Dictionary"
import { useUser } from "contexts/User"
import { useEnv } from "contexts/Env"

import useKeyboard from "hooks/useKeyboard"

import { useActions } from "./hooks/useActions"

import { getMultiPriceData } from "components/price/utils"
import { Icon } from "components/icon/Icon"
import { InnerList, FlexLi, ResultLine, TitleLine, Clear, ScreenReaderDiv } from "../store-locator/components/Search/SuggestionsList/styles"
import { Root, SuggList } from "./styles/suggestions"
import { SearchContainer, EraseButton } from "../store-locator/components/Search/styles"
import { SearchRoot, SearchButton, SearchInput } from "./styles/searchBox"
import { useSearch, SEARCH_RESULTS_PAGE_SLUG } from "./context"
import { updateSearchList } from "./utils"

export const SearchBox = props => {
  const { controller, inputRef } = props
  const console = useConsole()
  const [state, setState] = useState(process.browser && controller.state)
  const [showSuggestions, setShowSuggestions] = useState(false)
  const [searchList, setSearchList] = useState(false)
  const [searchFocus, setSearchFocus] = useState(false)
  const navigation = useNavigation()
  const header = useHeader()
  const locale = useLocale()
  const { countryCode } = useUser()
  const env = useEnv()
  const dictionary = useDictionary()

  const focusRef = useRef(false)

  const {
    activeFilter,
    setActiveFilter,
    queryValue,
    setQueryValue,
    setIsloading,
    setGroupedResults,
    setLastSearchValue,
    setValueWasCleared,
    isOnSearchResultsPage,
  } = useSearch()
  const { searchAllFilters, updateQueryWithValue } = useActions()

  const keyboardActive = useKeyboard()

  useLayoutEffect(() => controller.subscribe(() => setState(controller.state)), [controller])

  useLayoutEffect(() => {
    //clean up search when leave search results page
    if (!navigation.slug.includes(`/${SEARCH_RESULTS_PAGE_SLUG}`)) {
      clearCurrentSearch()
      setLastSearchValue("")
    }

    if (localStorage?.getItem("searchList")) setSearchList(JSON.parse(localStorage?.getItem("searchList")))
  }, [navigation.slug])

  useLayoutEffect(() => {
    if (global?.location?.search) {
      updateSearchList()

      setSearchList(JSON.parse(localStorage?.getItem("searchList")))
    }
  }, [global?.location?.search])

  useLayoutEffect(() => {
    if (!state?.suggestions) {
      return
    }
  }, [state?.suggestions])

  const localeCode = locale?.current?.codes?.www == locale.defaultLocale.codes.www ? "" : `${locale?.current?.codes?.www}/`

  const suggVariants = {
    hidden: {
      height: 0,
    },
    visible: {
      height: "auto",
      transition: {
        staggerChildren: 0.07,
        // delay: 1
      },
    },
  }
  const childVariants = {
    hidden: {
      opacity: 0,
      x: -10,
    },
    visible: {
      opacity: 1,
      x: 0,
      // transition: {
      //   delay: 1
      // }
    },
  }

  const handleSubmit = async e => {
    e.preventDefault()
    window.scrollTo(0, 0)
    setShowSuggestions(false)
    header.forceUnlock()

    setLastSearchValue(queryValue)

    if (isOnSearchResultsPage) {
      setIsloading(true)

      const data = await searchAllFilters(queryValue)
      const jsonStr = JSON.stringify(data)
      const groupedResults = JSON.parse(jsonStr)
      const watches = groupedResults?.watches
      if (watches) {
        const rmcs = watches.reduce((acc, { raw }) => acc.concat([raw.rlx_rmc]), [])
        const { priceList, popin } = await getMultiPriceData({ rmcs, countryCode, locale, env })
        const pmapped = priceList.reduce((acc, x) => Object.assign(acc, { [x.rmc]: x }), {})
        for (const w of watches) {
          w.raw.formattedPrice = pmapped[w.raw.rlx_rmc]?.formattedPrice
          w.raw.pricePopin = popin
        }
      }

      setGroupedResults(groupedResults)
      setActiveFilter("all")

      updateUrlParamater(queryValue)

      setIsloading(false)
    } else {
      setActiveFilter("all")
      setLastSearchValue("")

      navigation.push(`/${localeCode}${SEARCH_RESULTS_PAGE_SLUG}?q=${queryValue}`)
    }
  }

  const handleClickOnClear = () => {
    localStorage?.removeItem("searchList")

    setTimeout(() => {
      setSearchList(false)
    }, 100)
  }

  const handleClickOnSuggestion = async value => {
    setShowSuggestions(false)
    header.forceUnlock()

    setLastSearchValue(value)

    if (isOnSearchResultsPage) {
      setIsloading(true)

      if (activeFilter === "all") {
        const data = await searchAllFilters(value)
        const jsonStr = JSON.stringify(data)
        const groupedResults = JSON.parse(jsonStr)
        const watches = groupedResults?.watches

        if (watches) {
          const rmcs = watches.reduce((acc, { raw }) => acc.concat([raw.rlx_rmc]), [])

          const { priceList, popin, showPrices: _showPrices } = await getMultiPriceData({ rmcs, countryCode, locale, env })
          const showPrices = _showPrices !== false // weird webservice that only returns the value when false

          const pmapped = showPrices && priceList.reduce((acc, x) => Object.assign(acc, { [x.rmc]: x }), {})
          for (const w of watches) {
            if (showPrices && pmapped) {
              w.raw.formattedPrice = pmapped[w.raw.rlx_rmc]?.formattedPrice
              w.raw.pricePopin = popin
            } else {
              w.raw.noPrice = true
            }
          }
        }

        setGroupedResults(groupedResults)
      } else {
        //query will be launched on filter change in "results"
        setGroupedResults(null)
        setActiveFilter("all")
      }

      updateUrlParamater(value)

      setIsloading(false)
    } else {
      setLastSearchValue("")
      navigation.push(`/${localeCode}${SEARCH_RESULTS_PAGE_SLUG}?q=${value}`)
    }
  }

  const clearCurrentSearch = async () => {
    setShowSuggestions(false)
    await updateQueryWithValue("")
    setQueryValue("")
    controller.clear()
    setValueWasCleared(true)
    // setActiveFilter("all")

    if (keyboardActive) {
      inputRef.current.focus()
    }
  }

  const handleChange = e => {
    state?.suggestions?.length > 0 && setShowSuggestions(true)
    setValueWasCleared(false)
    controller.updateText(e.target.value)
    setQueryValue(e.target.value)
  }

  const handleFocus = e => {
    setTimeout(() => {
      setSearchFocus(true)
    }, 350)
  }

  const handleBlur = e => {
    if (keyboardActive) focusRef.current = true
    else focusRef.current = false

    if (!focusRef.current) {
      setTimeout(() => {
        setSearchFocus(false)
      }, 100)
    }
  }

  const updateUrlParamater = value => {
    if (process.browser) {
      const url = new URL(window.location)
      url.searchParams.set("q", value)
      window.history.pushState({}, "", url)
    }
  }

  console.verbose("SearchBox(%o)", props)

  return (
    <SearchRoot>
      <SearchContainer>
        <SearchInput
          id='search-input'
          ref={inputRef}
          tabindex='0'
          placeholder={dictionary.search()}
          value={state?.value ?? ""}
          onChange={e => handleChange(e)}
          onFocus={e => handleFocus(e)}
          onBlur={e => handleBlur(e)}
          onKeyDown={e => e.key === "Enter" && handleSubmit(e)}
          autoCapitalize='off'
          autoComplete='off'
          autoCorrect='off'
          spellCheck='false'
          type='search'
          maxLength='1010'
          enterKeyHint='search'
          aria-label={dictionary.searchAria()}
        />
        {queryValue && (
          <EraseButton type='button' aria-label={dictionary.clearSearch()} onClick={clearCurrentSearch} className={state?.value ? "visible" : ""}>
            <Icon type='close' />
          </EraseButton>
        )}

        <SearchButton type='button' onClick={handleSubmit} aria-label={dictionary.validateSearch()}>
          <Icon type='arrowRight' viewBox='0 0 15 15' />
        </SearchButton>
      </SearchContainer>

      {/* //todosearch: suggestions in separate component */}
      <AnimatePresence>
        {searchList && state?.value?.length === 0 && searchFocus && (
          <Root initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
            <SuggList>
              <TitleLine id='recentlySearchLabel' variants={childVariants}>
                {dictionary.recentlySearch()}
              </TitleLine>
              <ScreenReaderDiv aria-live='polite' className='sr-only'>
                {`${searchList.length} ${dictionary.ariaLiveRecentlySearch()}`}
              </ScreenReaderDiv>
              <Clear type='button' variants={childVariants} onClickCapture={handleClickOnClear} aria-label={dictionary.clear()}>
                {dictionary.clear()}
                <span>
                  <Icon type='close' viewBox='0 0 15 15' />
                </span>
              </Clear>

              <InnerList initial='hidden' animate='visible' variants={suggVariants} aria-labelledby='recentlySearchLabel'>
                {searchList.map((item, i) => {
                  return (
                    <FlexLi key={i} variants={childVariants}>
                      <ResultLine type='button' onClick={() => handleClickOnSuggestion(item)}>
                        <Icon type='search' viewBox='0 0 15 15' />
                        {item}
                      </ResultLine>
                    </FlexLi>
                  )
                })}
              </InnerList>
            </SuggList>
          </Root>
        )}

        {showSuggestions && state.suggestions.length && queryValue && (
          <Root initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
            <SuggList>
              <TitleLine id='suggestionsLabel' variants={childVariants}>
                {dictionary.suggestions()}
              </TitleLine>
              <ScreenReaderDiv aria-live='polite' className='sr-only'>
                {`${state.suggestions.length} ${dictionary.ariaLiveSuggestions()}`}
              </ScreenReaderDiv>
              <InnerList initial='hidden' animate={showSuggestions ? "visible" : "hidden"} variants={suggVariants} aria-labelledby='suggestionsLabel'>
                {state.suggestions.map(suggestion => {
                  return (
                    <FlexLi key={suggestion.rawValue} variants={childVariants}>
                      <ResultLine
                        type='button'
                        onClick={() => handleClickOnSuggestion(suggestion.rawValue)}
                        dangerouslySetInnerHTML={{ __html: suggestion.highlightedValue }}
                      ></ResultLine>
                    </FlexLi>
                  )
                })}
              </InnerList>
            </SuggList>
          </Root>
        )}
      </AnimatePresence>
    </SearchRoot>
  )
}
