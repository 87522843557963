import { clearAllBodyScrollLocks, disableBodyScroll, enableBodyScroll } from "body-scroll-lock"
import { useTheme } from "contexts/Theme"

const lockers = new Set()

export default function useScrollLock() {
  const theme = useTheme()
  const cssLock = theme.classNames.lock
  let raf

  const lock = node => {
    lockers.add(node)
    disableBodyScroll(node, {
      allowTouchMove: target => {
        return node === target || node.contains(target)
      },
    })

    if (lockers.size) {
      document.documentElement.classList.add(cssLock)
    }

    return () => unlock(node)
  }

  const unlock = node => {
    lockers.delete(node)
    enableBodyScroll(node)

    if (!lockers.size) {
      document.documentElement.classList.remove(cssLock)
    }

    cancelAnimationFrame(raf)
    raf = requestAnimationFrame(() => lockers.forEach(node => !document.body.contains(node) && unlock(node)))
  }

  const forceUnlock = () => {
    document.documentElement.classList.remove(cssLock)
    lockers.clear()
    clearAllBodyScrollLocks()
  }

  return {
    lock,
    unlock,
    forceUnlock,
  }
}
