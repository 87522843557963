import styled from "@emotion/styled"
import FocusTrap from "focus-trap-react"
import { AnimatePresence, motion } from "framer-motion"
import { useEffect, useLayoutEffect, useRef, useState } from "react"
import { createPortal } from "react-dom"
import { useLocation } from "react-router-dom"

import { useConsole } from "contexts/Console"
import { useDictionary } from "contexts/Dictionary"
import { useEnv } from "contexts/Env"
import { useLocale } from "contexts/Locale"
import { useMenu } from "contexts/Menu"
import { useNavigation } from "contexts/Navigation"
import { useUser } from "contexts/User"

import getMediaQuery from "css/breakpoints"
import * as button from "css/buttons"
import { fullGrid } from "css/grid"
import * as text from "css/text"

import useScrollLock from "hooks/useScrollLock"

import { Icon } from "components/icon/Icon"

const Root = styled(motion.div)`
  width: 100vw;
  height: 100vh;
  position: fixed;
  inset: 0;
  z-index: calc(var(--z-top, 1000));

  background: linear-gradient(90deg, #0b3e27, #197149);
`

const SubContainer = styled.div`
  ${fullGrid}
  grid-template-rows: min-content min-content;

  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;

  &::-webkit-scrollbar {
    display: none;
  }
  scrollbar-width: none;
`

const Title = styled(motion.h2)`
  ${text.headline50}
  margin-block-end: 2.5rem;

  grid-column: main;

  padding-block-start: 6rem;

  ${getMediaQuery("m")} {
    padding-block-start: 10rem;
    grid-column: col 5 / span 6;
  }

  em {
    font-style: normal;
    color: rgb(var(--light-green));
    display: block;
  }
`

const List = styled(motion.ul)`
  grid-column: main;
  column-count: 2;
  grid-gap: var(--grid-gap);

  padding-block-end: 5rem;
  ${getMediaQuery("m")} {
    grid-column: col 5 / span 6;
    column-count: 3;
    padding-block-end: 10rem;
  }
`

const Item = styled(motion.li)`
  padding-inline: 0 0.3rem;
  padding-block: 0.3rem;
`

const Link = styled.a`
  position: relative;
  font-size: 14px;
  ${text.legend110}
  ${text.normal}

  text-decoration: none;
  transition: color 0.3s;
  will-change: color;
  color: rgb(var(--pure-white));

  &.active {
    color: rgb(var(--pure-white));

    &::after {
      content: "";
      position: relative;
      display: inline-block;
      background-color: currentColor;
      border-radius: 50%;
      width: 0.5rem;
      height: 0.5rem;
      margin-inline-start: 0.5rem;
      margin-block-end: 0.1rem;
    }
  }

  @media (hover: hover) {
    &:hover {
      color: rgb(var(--ocean-green));
    }
  }
`

const Close = styled(motion.button)`
  ${button.buttonCommon}
  ${button.buttonIcon}
  ${button.translucentDark}
  --spacing: calc(var(--outer-margin) - var(--height) / 2);
  ${getMediaQuery("m")} {
    --spacing: var(--height);
  }
  position: absolute;
  top: var(--spacing);
  right: var(--spacing);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border: none;
  cursor: pointer;
  z-index: 1;
`

export default function Languages({ title }) {
  const console = useConsole()

  const env = useEnv()
  const dictionary = useDictionary()
  const location = useLocation()
  const {
    locales,
    current: {
      codes: { www: currLocale },
    },
  } = useLocale()
  const navigation = useNavigation()
  const user = useUser()
  const menu = useMenu()
  const scrollLock = useScrollLock()

  const [isLang, setIsLang] = useState(false)

  const closeRef = useRef()
  const ref = useRef()

  const setLocaleAndNavigate = e => {
    e.preventDefault()
    onClose()
    user.locale = e.target.getAttribute("lang")
    requestAnimationFrame(() => navigation.navigate(e.target.href))
  }

  function onStateChange(bool) {
    setIsLang(bool)
  }
  useEffect(() => menu.langIsOpen.onChange(onStateChange))

  function onClose() {
    menu.langIsOpen.set(false)
  }

  useLayoutEffect(() => {
    const r = ref.current || document.body
    if (isLang) {
      scrollLock.lock(r)
    } else {
      scrollLock.unlock(r)
    }
    return () => scrollLock.unlock(r)
  }, [isLang])

  const variants = {
    hide: {
      y: "15%",
      opacity: 0,
      transition: { duration: 0.3, delay: 0.15 },
    },
    show: { y: 0, opacity: 1 },
  }

  const subVariants = {
    hide: { opacity: 0, transition: { duration: 0.15 } },
    show: { opacity: 1, transition: { duration: 0.15, delay: 0.15 } },
  }

  console.verbose("Menu:Languages(%o)")
  return (
    process.browser &&
    createPortal(
      <AnimatePresence>
        {isLang && (
          <FocusTrap focusTrapOptions={{ onDeactivate: onClose }}>
            <Root
              ref={ref}
              className='dark-theme'
              variants={variants}
              initial='hide'
              animate='show'
              exit='hide'
              transition={{ duration: 0.3, ease: "easeOut" }}
              role='dialog'
              aria-modal='true'
              aria-labelledby='language-heading'
            >
              <Close ref={closeRef} aria-label={dictionary.popinClose()} onClick={onClose} className='icon translucent-dark' variants={subVariants}>
                <Icon type='close' />
                <span>{dictionary.popinClose()}</span>
              </Close>
              <SubContainer>
                <Title id='language-heading' dangerouslySetInnerHTML={{ __html: title }} variants={subVariants} />
                <List variants={subVariants}>
                  {locales.map(locale => {
                    let href =
                      env.cn && locale.external
                        ? new URL(navigation.localize(navigation.slug, locale.codes.www), "https://rolex.com").href
                        : navigation.localize(navigation.slug, locale.codes.www)
                    href += location.search

                    return (
                      <Item key={locale.codes.www}>
                        <Link
                          href={href}
                          lang={locale.codes.www}
                          dir={locale.dir}
                          onClick={setLocaleAndNavigate}
                          className={locale.codes.www === currLocale ? "active" : null}
                          aria-current={locale.codes.www === currLocale || null}
                          target='_self'
                        >
                          {locale.label}
                        </Link>
                      </Item>
                    )
                  })}
                </List>
              </SubContainer>
            </Root>
          </FocusTrap>
        )}
      </AnimatePresence>,
      document.body
    )
  )
}
